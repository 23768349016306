/*
body {
  border: 5px solid red;
}
 */

/* Categories in menu desktop */
.menu-categories{
  @apply my-0 block;

  a{
    @apply text-sm lg:text-base leading-5 text-left cursor-pointer text-white font-satoshi hover:text-cm;
  }
}

/* menu team in header menu */
.team-menu-desktop {
  @apply block;
  menu {
    @apply flex flex-row justify-evenly mx-0 my-2;
    img {
      @apply inline transition-[0.3s] duration-[all] ease-[ease-in-out] hover:scale-[1.4];
    }
  }
}